import axios from 'axios';
import Config from "@/Config";

class PersistentStorage {
    jwt = false;
    axios = axios.create({
        baseURL: Config.API_URL
    });

    async login(username, password) {
        let result = false;
        try {
            result = await this.axios.post('jwt/login', {user: username, password: password});
        } catch(e) {
            this.setJwt(false);
        }

        if(result && result.status == 200) {
            this.setJwt(result.data.data.jwt);
        } else {
            this.setJwt(false);
        }

    }

    setJwt(jwt) {
        this.jwt = jwt;
        this.axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.jwt;
    }

    getShowLogin() {
        return this.jwt === false;
    }

    async getObjectList(entityName, filter, offset, count) {
        return this.axios.get(entityName + '?filter=' + filter + (offset?'&offset=' + offset:'') + (count?'&count=' + count:''))
            .then(function (result) {
                return result.data.data;
            }, function(error) {
                this.setJwt(false);
                throw error;
            }.bind(this));
    }

    async getObject(entityName, id) {
        return this.axios.get(entityName + '/' + id)
            .then(function(result) {
                return result.data.data;
            }, function(error) {
                this.setJwt(false);
                throw error;
            }.bind(this));
    }

    async createObject(entityName, obj) {
        return this.axios.post(entityName, obj)
            .then(function(response) {
                return response.data.data;
            });
    }

    async updateObject(entityName, obj) {
        return this.axios.put(entityName + '/' + obj.id, obj)
            .then(function (response) {
                return response.data.data;
            });
    }

    async saveObject(entityName, obj) {
        if(obj.id) {
            return this.updateObject(entityName, obj);
        } else {
            return this.createObject(entityName, obj);
        }
    }

    async deleteObject(entityName, id) {
        return this.axios.delete(entityName + '/' + id);
    }

    async uploadFile(entityName, id, file, onUploadProgress) {
        let formdata = new FormData();
        formdata.append('file', file);
        this.uploadProgress = 0;
        this.fileUploading = true;
        return this.axios.post(entityName + (id?'/' + id:''), formdata,
            {
                headers: {"Content-Type": "multipart/form-data"},
                onUploadProgress: onUploadProgress
            });
    }
}

let persistentStorage = new PersistentStorage();
export default persistentStorage;
