<template>
  <div class="audioguides-data-table-component">
  <v-data-table
      :headers="headers"
      :items="audioguides"
      sort-by="name"
      class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar
          flat
      >
        <v-toolbar-title>Audioguides</v-toolbar-title>
        <v-divider
            class="mx-4"
            inset
            vertical
        ></v-divider>
        <v-spacer></v-spacer>
        <v-dialog
            v-model="dialog"
            max-width="500px"
            persistent
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                color="primary"
                dark
                class="mb-2"
                v-bind="attrs"
                v-on="on"
            >
              Neuer Audioguide
            </v-btn>
          </template>

          <v-card>

            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
              <v-spacer></v-spacer>
              <v-btn
              @click="showQRCode">
              QR Code drucken
              </v-btn>
            </v-card-title>
            <v-card-text>
              <v-alert v-show="error"
                  color="red"
                  type="error"
              >{{error}}</v-alert>
              <v-tabs v-model="languageTab">
                <v-tab
                    v-for="language in languages"
                    :key="language.id"
                >
                  <img :src="language.flag_img_url" class="flag-img">
                </v-tab>
              </v-tabs>

              <v-tabs-items
                  v-model="languageTab"
              >
                <v-tab-item
                  v-for="language in languages"
                  :key="language.id"
                  >
                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                            v-model="editedItem.translations[language.id].name"
                            label="Name"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-textarea
                            v-model="editedItem.translations[language.id].description"
                            auto-grow
                            filled
                            color="deep-purple"
                            label="Beschreibung"
                        ></v-textarea>
                      </v-col>
                      <v-col cols="12">
                        <v-file-input
                            label="Audiodatei"
                            accept=".mp3"
                            show-size
                            v-model="audioFiles[language.id]"
                        >
                        </v-file-input>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-tab-item>

              </v-tabs-items>
            </v-card-text>

            <v-card-actions>
              <v-container>
                <v-row>
                  <v-progress-linear
                      v-show="showUploadProgress"
                      v-model="uploadProgress"
                      rounded
                      height="20"
                  >
                    {{progressText}} - {{uploadProgress}}%
                  </v-progress-linear>
                </v-row>
                <v-row>
                  <v-spacer></v-spacer>
                  <v-btn
                      color="blue darken-1"
                      text
                      @click="close"
                      :disabled="saveBtnDisabled"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                      color="blue darken-1"
                      text
                      @click="save"
                      :disabled="saveBtnDisabled"
                  >
                    Save
                  </v-btn>
                </v-row>
              </v-container>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="540px">
          <v-card>
            <v-card-title class="headline">Wollen Sie diesen Eintrag wirklich löschen?</v-card-title>
            <v-card-text>
              <v-alert v-show="error"
                       color="red"
                       type="error"
              >{{error}}</v-alert>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete">Abbrechen</v-btn>
              <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.name="{item}">
      {{item.translations[stdLanguageId]?item.translations[stdLanguageId].name:'nicht übersetzt'}}
    </template>
    <template v-slot:[buildLanguageColValueSlotString(language.id)]="{item}" v-for="language of languages">
      <a
          v-if="item.translations[language.id].filename"
          :href="item.translations[language.id].filename"
          :key="language.id"
          target="_blank">
        Play
      </a>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon
          small
          class="mr-2"
          @click="editItem(item)"
      >
        bearbeiten
        mdi-pencil
      </v-icon>
      <v-icon
          small
          @click="deleteItem(item)"
      >
        löschen
        mdi-delete
      </v-icon>
    </template>
    <template v-slot:no-data>
      <v-alert
          v-show="error"
          color="red"
          type="error">
        {{error}}
      </v-alert>
      <v-btn
          color="primary"
          @click="initialize"
      >
        Neuladen
      </v-btn>
    </template>
  </v-data-table>
    <v-snackbar
        color="red"
        v-model="showErrorSnackbar"
    >{{error}}</v-snackbar>
  </div>
</template>

<style lang="css">
  .flag-img {
    width: 50px;
  }
</style>

<script>
import Config from '@/Config';
import persistentStorage from "@/services/PersistentStorage";
export default {
  name: "AudioGuidesDataTable",
  data: () => ({
    error: '',
    dialog: false,
    dialogDelete: false,
    uploadProgress: 0,
    showUploadProgress: false,
    progressText: '',
    saveBtnDisabled: false,
    fileUploading: false,
    audioFiles: {},
    audioguides: [],
    stdLanguageId: 0,
    languageTab: null,
    languages: [],
    editedIndex: -1,
    editedItem: {
      translations: {

      }
    },
    defaultItem: {
      translations: {
      }
    },
  }),

  computed: {
    showErrorSnackbar: {
      set(val) {
        if(val == false) {
          this.error = '';
        }
      },
      get() {
        return this.error != ''
      }
    },
    headers: {
      get() {
        let headers = [
          {
            text: 'Name',
            align: 'start',
            value: 'name',
          }];
        for(let language of this.languages) {
          headers.push(
              {
                text: language.name,
                value: this.buildLanguageColValueString(language.id),
                sortable: false
              })
        }
        headers.push(
          {
            text: 'Aktionen',
            value: 'actions',
            sortable: false
          }
        );
        return headers;
      }
    },

    formTitle () {
      return this.editedIndex === -1 ? 'Neuer AudioGuide' : 'AudioGuide bearbeiten'
    },
  },

  watch: {
    dialog (val) {
      val || this.close()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    },
  },

  created () {
    this.initialize()
  },

  methods: {
    initialize () {
      this.queryLanguages();
      this.queryAudioGuides('');
    },

    editItem (item) {
      this.editedIndex = this.audioguides.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
      this.error = '';
      this.saveBtnDisabled = false;
      this.progressText = '';
      this.showUploadProgress = false;
      this.uploadProgress = 0;
    },

    deleteItem (item) {
      this.editedIndex = this.audioguides.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.error = '';
      this.dialogDelete = true
    },

    deleteItemConfirm () {
      persistentStorage.deleteObject('audioguides', this.audioguides[this.editedIndex].id)
        .then(
          function() {
            this.queryAudioGuides();
            this.closeDelete();
          }.bind(this),
          function(error) {
            this.error = error;
          }.bind(this));
    },

    close () {
      this.dialog = false
      this.error = '';
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    save () {
      this.saveBtnDisabled = true;
      persistentStorage.saveObject('audioguides', this.editedItem)
        .then(function(response) {
          this.editedItem.id = response.id;
          this.error = '';
          this.uploadFiles();
        }.bind(this), function(error) {
          this.error = error;
        }.bind(this));
    },
    async uploadFiles() {
      for(let language of this.languages) {
        if(this.audioFiles[language.id]) {
          this.showUploadProgress = true;
          this.progressText = 'Lade hoch: ' + this.audioFiles[language.id].name;
          this.uploadProgress = 0;
          persistentStorage.uploadFile('audioguides/' + this.editedItem.id + '/audiofiles', language.isocode, this.audioFiles[language.id],
              function(progress) {
                this.uploadProgress = Math.round(progress.loaded * 100 / progress.total);
              }.bind(this))
              .then(function() {
                this.uploadFiles();
              }.bind(this), function(error) {
                this.error = error;
              }.bind(this));
          this.audioFiles[language.id] = null;
          return;
        }
      }
      this.showUploadProgress = false;
      this.progressText = '';
      this.uploadProgress = 0;
      this.saveBtnDisabled = false;
      this.close();
      this.queryAudioGuides();
    },
    async queryLanguages() {
      this.loading = true;
      persistentStorage.getObjectList('languages')
        .then(function(result) {
          this.languages = result;
          for(let language of this.languages) {
            this.editedItem.translations[language.id] = {
              languageId: language.id,
              name: '',
              description: ''
            }
            this.defaultItem.translations[language.id] = {
              languageId: language.id,
              name: '',
              description: ''
            }
            this.audioFiles[language.id] = null;
          }

          this.loading = false;
        }.bind(this),
          function(error) {
          this.languages = [];
          this.error = error;
          this.loading = false;
          }.bind(this)
        );
    },
    async queryAudioGuides (v) {
      this.loading = true;
      let stdLanguage = await persistentStorage.getObject('languages', 'standard');
      this.stdLanguageId = stdLanguage.id;
      persistentStorage.getObjectList('audioguides', v)
        .then(function(result) {
          this.audioguides = result;
          for(let audioguide of this.audioguides) {
            for(let language of this.languages) {
              if(!audioguide.translations[language.id]) {
                audioguide.translations[language.id] = {
                  languageId: language.id,
                  name: '',
                  description: ''
                }
              }
            }
          }
          this.loading = false;
        }.bind(this), function(error) {
          this.audioguides = [];
          this.error = error;
          this.loading = false;
        }.bind(this));
    },
    showQRCode() {
      window.open(Config.API_URL + 'audioguides/' + this.editedItem.id + '/qrcode');
    },
    buildLanguageColValueSlotString(languageId) {
      return 'item.' + this.buildLanguageColValueString(languageId);
    },
    buildLanguageColValueString(languageId) {
      return 'filename'+languageId;
    }
  },
}
</script>