<template>
  <v-app>
    <v-app-bar
      app
      color="primary"
      dark
    >

    </v-app-bar>
    <v-navigation-drawer
        permanent
        expand-on-hover
        app
    >
      <v-list>
        <v-list-item>
          <v-list-item-icon><v-icon>mdi-flag</v-icon></v-list-item-icon>
          <v-list-item-title><router-link to="/languages">Sprachen</router-link></v-list-item-title>
        </v-list-item>
        <v-list-item>
          <v-list-item-icon><v-icon>mdi-volume-high</v-icon></v-list-item-icon>
          <v-list-item-title><router-link to="/audioguides">Audioguides</router-link></v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-main app>
      <router-view></router-view>
    </v-main>
    <LoginDialog></LoginDialog>
  </v-app>
</template>

<script>
import LoginDialog from "@/components/LoginDialog";

export default {
  name: 'App',

  components: {
    LoginDialog
  },

};
</script>
