<template>
  <v-dialog
      v-model="showLogin"
      persistent
      max-width="500px">
    <v-form @submit.prevent="login">
      <v-card>
        <v-card-title>
          <v-row>
            <v-col>
              Melden Sie sich an
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
            <v-row>
              <v-col>
                <v-text-field v-model="username" label="Username"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                    v-model="password"
                    label="Passwort"
                    :append-icon="showPassword?'mdi-eye':'mdi-eye-off'"
                    :type="showPassword?'text':'password'"
                    @click:append="showPassword = !showPassword"
                ></v-text-field>
              </v-col>
            </v-row>
        </v-card-text>
        <v-card-actions>
          <v-row class="ma-0">
            <v-col class="text-right">
              <v-btn type="submit">Anmelden</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import persistentStorage from '@/services/PersistentStorage';
export default {
  name: "LoginDialog",
  data: () => ({
    username: '',
    password: '',
    showPassword: false
  }),
  computed: {
    showLogin: {
      cache: false,
      get: () => {return persistentStorage.getShowLogin();}
    }
  },
  methods: {
    login: async function() {
      await persistentStorage.login(this.username, this.password);
      this.$forceUpdate();
    }
  }

}
</script>

<style scoped>

</style>