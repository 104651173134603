import Vue from 'vue'
import App from './App.vue'

Vue.config.productionTip = false

import axios from 'axios';
import VueAxios from 'vue-axios';
import vuetify from './plugins/vuetify';
import router from './router'
Vue.use(VueAxios, axios);

new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
